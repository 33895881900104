import { base_wx_edit, base_wx_info } from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  components: {
    editor
  },
  name: "index",
  props: ['Row'],
  data() {
    return {
      Info: {
        id: '',
        wx_web: ''
      }
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      base_wx_info().then(res => {
        if (res.data) {
          this.Info = res.data;
          this.setText();
        }
      });
    },
    subData() {
      //提交
      if (!this.Info.wx_web) {
        this.$root.ElMessage.error('微官网内容不能为空！');
        return false;
      }
      base_wx_edit(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    setText() {
      this.$refs.editor.setText(this.Info.wx_web);
    },
    getText(html) {
      this.Info.wx_web = html;
    }
  }
};